import logger from '../utils/logger';
import stores from '.';

const FETCH_SETTINGS = [
  'unresolvedBounceIndicatorFeatureAvailable',
  'liveFormFieldsDataEnabled',
  'witnessReplaceAvailable',
  'privateRecipientEnabled',
  'agreementDeliveryMethods'
];

/**
 * Settings for user: /user/me/settings/search
 *
 */
export default class UserSettingsSearch {
  constructor(Api) {
    // setting default values
    this.settings = new Api.Users({ id: 'me' }).search;
    this.promise = null;
    this.rejected = false;
  }

  fetch() {
    stores.Floodgate.ready().then(() => {
      const hasSettingsSearchApiEnabled = stores.Floodgate.hasSettingsSearchApiEnabled();
      // TODO: Remove this condition once floodgate flag dependency is eliminated. If not this feature will not be enabled in GovCloud for 15.1
      if (!hasSettingsSearchApiEnabled) {
        return Promise.resolve(this);
      }
      this.promise = this.settings
        .save({ settings: FETCH_SETTINGS })
        .then(res => {
          return this; // resolved value
        })
        .catch(e => {
          // the failure is caught and not thrown again, so the context board may render
          // even in this scenario.
          this.rejected = true;
          logger.warn('Unable to get user settings search', { error: e });
        });
    });
  }

  ready() {
    return this.promise;
  }

  /* insert comment here
   * Checks whether unresolvedBounceIndicatorFeatureAvailable setting is true or false; if it does not exist
   * default to return false
   * Returns: bool
   */
  isUnresolvedBounceIndicatorFeatureAvailable() {
    return this.settings.list.getByName('unresolvedBounceIndicatorFeatureAvailable');
  }

  isLiveFormFieldDataFeatureEnabled() {
    return this.settings.list.getByName('liveFormFieldsDataEnabled');
  }

  isWitnessReplaceAvailable() {
    return this.settings.list.getByName('witnessReplaceAvailable');
  }

  isPrivateRecipientEnabled() {
    return this.settings.list.getByName('privateRecipientEnabled');
  }

  isSmsDeliveryEnabled() {
    const agreementDeliveryMethods = this.settings.list.getByName('agreementDeliveryMethods');
    return agreementDeliveryMethods && agreementDeliveryMethods.includes('SMS');
  }
}
